import marked from 'marked'
import DOMPurify from 'dompurify'

class Marked extends React.Component {

  rawMarkup = null
  data = null

  componentDidMount() {
    this.data = MC.extend(true, {}, this.props.data.param)
    this.update(this.props);
  }

  componentDidUpdate() {
    if (JSON.stringify(this.data) != JSON.stringify(this.props.data.param)) {
      this.data = MC.extend(true, {}, this.props.data.param)
      this.update(this.props);
    }
  }

  update(props) {
    const markdownText = MC.getFieldParamValue(props.data.param, 'value')
    const baseUrl = MC.getFieldParamValue(props.data.param, '@baseUrl')
    if (!MC.isNullOrEmpty(markdownText)) {
      this.rawMarkup = DOMPurify.sanitize(marked(markdownText, { baseUrl: baseUrl }));
    } else {
      this.rawMarkup = '';
    }
    this.forceUpdate();
  }
  
  createMarkup() {
    return { __html: this.rawMarkup };
  }

  render() {
    return <div dangerouslySetInnerHTML={this.createMarkup()}/>
  }

}

MC.registerReactRomponent('marked', Marked)